import React from 'react';
import "../CSS/Rooms.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

import deluxesuiterooms from "../Images/Suite Room/GOPR0461.webp";
import deluxesuiterooms01 from "../Images/Suite Room/GOPR0466.webp";
import deluxesuiterooms02 from "../Images/Suite Room/GOPR0526.webp";
import deluxesuiterooms03 from "../Images/Suite Room/IMG_3116.webp";
import deluxesuiterooms04 from "../Images/Suite Room/GOPR0651.webp";

import familycottagerooms from "../Images/Family Cottage/GOPR0448.webp";
import familycottagerooms01 from "../Images/Family Cottage/GOPR0454.webp";
import familycottagerooms02 from "../Images/Family Cottage/GOPR0438.webp";
import familycottagerooms03 from "../Images/Family Cottage/GOPR0442.webp";

import ContainerHouse from "../Images/Container House/IMG_3138.webp";
import ContainerHouse01 from "../Images/Container House/GOPR0487.webp";
import ContainerHouse02 from "../Images/Container House/GOPR0489.webp";
import ContainerHouse03 from "../Images/Container House/GOPR0490.webp";
import ContainerHouse04 from "../Images/Container House/IMG_3142.webp";

import kittypartyrooms from '../Images/Lower Dormitory/IMG_3421.webp';
import kittypartyrooms01 from '../Images/Lower Dormitory/IMG_3420.webp';
import kittypartyrooms02 from '../Images/Lower Dormitory/IMG_3419.webp';
import kittypartyrooms03 from '../Images/Lower Dormitory/IMG_3426.webp';

import lakeviewdormitory from "../Images/Upper Dormitory/IMG_3290.webp";
import lakeviewdormitory01 from "../Images/Upper Dormitory/IMG_3289.webp";
import lakeviewdormitory02 from "../Images/Upper Dormitory/IMG_3291.webp";
import lakeviewdormitory03 from "../Images/Upper Dormitory/IMG_3297.webp";
import lakeviewdormitory04 from "../Images/Upper Dormitory/IMG_3287.webp";

import tents from "../Images/Tents/GOPR0679.webp";
import tents01 from "../Images/Tents/GOPR0682.webp";
import tents02 from "../Images/Tents/GOPR0684.webp";
import tents03 from "../Images/Tents/GOPR0690.webp";
import tents04 from "../Images/Tents/GOPR0692.webp";
import tents05 from "../Images/Tents/IMG_3226.webp";

const Rooms = () => {
    return (
        <>
            <section className='whyabss-section-2'>
                <div className='container'>
                    <div className='whyabss-section-2-details-con'>
                        <div className='why-abss-section'>
                            <div className='about-section-image-details'>
                                <div className='row'>
                                    <div className='col-md-5 p-0'>
                                        <Swiper
                                            spaceBetween={30}
                                            centeredSlides={true}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={deluxesuiterooms} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={deluxesuiterooms03} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={deluxesuiterooms04} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={deluxesuiterooms01} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={deluxesuiterooms02} alt='' />
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className='col-md-7 p-0'>
                                        <div className='about-section-page-about-details-con'>
                                            <div className='about-section-page-about-details'>
                                                <div className='about-section-page-about-info'>
                                                    <h3>Deluxe Suite Rooms</h3>
                                                    <p>Experience the epitome of luxury in our Deluxe Suite Rooms at Dahigaon Lake Resort-Karjat. Each suite is a haven of elegance and comfort, featuring sophisticated décor, a plush king-sized bed, and state-of-the-art amenities. Large windows frame stunning views of the serene lake and lush greenery, creating an enchanting backdrop for your stay. Whether you're sipping coffee on the private balcony or unwinding in the spacious living area, our Deluxe Suite Rooms promise an unforgettable retreat amidst the tranquil beauty of Karjat.</p>
                                                </div>
                                            </div>
                                            <div className='about-section-page-about-details-style'>
                                                <h4>1</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='about-section-image-details'>
                                <div className='row'>
                                    <div className='col-md-5 p-0 order-md-2 order-1'>
                                        <Swiper
                                            spaceBetween={30}
                                            centeredSlides={true}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={familycottagerooms} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={familycottagerooms01} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={familycottagerooms02} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={familycottagerooms03} alt='' />
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className='col-md-7 p-0 order-md-2 order-1'>
                                        <div className='about-section-page-about-details-con'>
                                            <div className='about-section-page-about-details2'>
                                                <div className='about-section-page-about-info'>
                                                    <h3>Family Cottage Rooms</h3>
                                                    <p>Welcome to the Family Cottage Rooms at Dahigaon Lake Resort-Karjat, where comfort meets nature in a cozy, family-friendly setting. Our spacious cottages are designed to accommodate larger groups and families, offering multiple bedrooms, a living area, and a private veranda for you to enjoy. Each cottage is outfitted with all the comforts of home, including modern amenities and elegant furnishings, set against the stunning backdrop of Karjat’s natural beauty. Perfect for family vacations or group retreats, our Family Cottage Rooms provide a private, serene escape where memories are waiting to be made.</p>
                                                </div>
                                            </div>
                                            <div className='about-section-page-about-details-style2'>
                                                <h4>2</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='about-section-image-details'>
                                <div className='row'>
                                    <div className='col-md-5 p-0'>
                                        <Swiper
                                            spaceBetween={30}
                                            centeredSlides={true}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={ContainerHouse} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={ContainerHouse01} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={ContainerHouse02} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={ContainerHouse03} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={ContainerHouse04} alt='' />
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className='col-md-7 p-0'>
                                        <div className='about-section-page-about-details-con'>
                                            <div className='about-section-page-about-details'>
                                                <div className='about-section-page-about-info'>
                                                    <h3>Container House</h3>
                                                    <p>Discover a unique and eco-friendly stay in our Container Houses at Dahigaon Lake Resort-Karjat. These innovative accommodations blend modern architectural design with environmental sensitivity, offering a stylish yet sustainable way to experience the breathtaking surroundings of Karjat. Each Container House is fully equipped with contemporary amenities, chic décor, and floor-to-ceiling windows that invite the natural beauty inside. Ideal for those who appreciate creativity and care for the environment, our Container Houses provide a distinctive and comfortable retreat that makes every moment of your stay memorable.</p>
                                                </div>
                                            </div>
                                            <div className='about-section-page-about-details-style'>
                                                <h4>3</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='about-section-image-details'>
                                <div className='row'>
                                    <div className='col-md-5 p-0 order-md-2 order-1'>
                                        <Swiper
                                            spaceBetween={30}
                                            centeredSlides={true}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={kittypartyrooms} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={kittypartyrooms01} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={kittypartyrooms02} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={kittypartyrooms03} alt='' />
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className='col-md-7 p-0 order-md-1 order-2'>
                                        <div className='about-section-page-about-details-con'>
                                            <div className='about-section-page-about-details2'>
                                                <div className='about-section-page-about-info'>
                                                    <h3>A/C Kitty Party Rooms / Dormitory</h3>
                                                    <p>Celebrate and stay together in style at the A/C Kitty Party Rooms and Dormitory at Dahigaon Lake Resort-Karjat. Designed to cater to both small gatherings and larger groups, these rooms offer a versatile space that is perfect for kitty parties, informal meetings, or social get-togethers. Each room is air-conditioned and furnished with comfortable seating and modern amenities to ensure your event is both enjoyable and memorable. With plenty of space to relax, mingle, and celebrate, our Kitty Party Rooms and Dormitory provide a fun and festive environment set against the picturesque backdrop of Karjat’s lush landscapes.</p>
                                                </div>
                                            </div>
                                            <div className='about-section-page-about-details-style2'>
                                                <h4>4</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='about-section-image-details'>
                                <div className='row'>
                                    <div className='col-md-5 p-0'>
                                        <Swiper
                                            spaceBetween={30}
                                            centeredSlides={true}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={lakeviewdormitory} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={lakeviewdormitory01} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={lakeviewdormitory02} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={lakeviewdormitory03} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={lakeviewdormitory04} alt='' />
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className='col-md-7 p-0'>
                                        <div className='about-section-page-about-details-con'>
                                            <div className='about-section-page-about-details'>
                                                <div className='about-section-page-about-info'>
                                                    <h3>A/C lake view dormitory</h3>
                                                    <p>Experience the beauty of Karjat from our A/C Lake View Dormitory at Dahigaon Lake Resort-Karjat. Ideal for groups and families, these dormitory rooms provide a comfortable and communal setting with stunning views overlooking the tranquil lake. Air-conditioned and well-appointed with all necessary amenities, our dormitories offer a perfect blend of convenience and scenic beauty. Whether you’re here for a team outing or a family reunion, the Lake View Dormitory ensures a memorable stay, where you can unwind and reconnect in the heart of nature.</p>
                                                </div>
                                            </div>
                                            <div className='about-section-page-about-details-style'>
                                                <h4>5</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='about-section-image-details'>
                                <div className='row'>
                                    <div className='col-md-5 p-0 order-md-2 order-1'>
                                        <Swiper
                                            spaceBetween={30}
                                            centeredSlides={true}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={tents} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={tents01} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={tents02} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={tents03} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={tents04} alt='' />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='about-page-section-image-con'>
                                                    <img src={tents05} alt='' />
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className='col-md-7 p-0 order-md-1 order-2'>
                                        <div className='about-section-page-about-details-con'>
                                            <div className='about-section-page-about-details2'>
                                                <div className='about-section-page-about-info'>
                                                    <h3>Tents</h3>
                                                    <p>Immerse yourself in the great outdoors without sacrificing comfort in our luxurious Tents at Dahigaon Lake Resort-Karjat. Each tent is thoughtfully designed to offer a unique glamping experience, combining the thrill of camping with the comforts of a hotel. Nestled in the lush surroundings of Karjat, our tents provide a tranquil retreat with stunning views of the natural landscape. Equipped with all modern amenities, plush bedding, and a private seating area outside, our tents offer a perfect blend of adventure and relaxation, making them an ideal choice for nature lovers and adventure seekers alike.</p>
                                                </div>
                                            </div>
                                            <div className='about-section-page-about-details-style2'>
                                                <h4>4</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Rooms