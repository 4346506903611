import React, { useEffect, useLayoutEffect, useState } from 'react';
import "../CSS/HomePage.css";
import divider from "../Images/divider.png";
import { ImSpoonKnife } from 'react-icons/im';
import { FaWifi } from 'react-icons/fa';
import { BsDisplay } from 'react-icons/bs';
import { FaShower } from 'react-icons/fa';
import { IoBed } from 'react-icons/io5';
import { MdPool } from 'react-icons/md';
import { AiFillCar } from 'react-icons/ai';
import { FaDharmachakra } from 'react-icons/fa';
import { MdFoodBank } from 'react-icons/md';
import ReviewsSwaiper from "../Pages/ReviewsSwaiper";
import ShowImage01 from "../Images/divider.png";
import { RiWhatsappLine } from 'react-icons/ri';
import { Button, Modal } from 'antd';
// section 4 Images 
import leftOverlay from "../Images/Banner/rainbow.webp";
// Section 5 Images 
import EnjoyFriend1 from "../Images/Activities/Activities rifle.webp";
import EnjoyFriend2 from "../Images/Activities/Activities Zipline.webp";
import Enjoypartner2 from "../Images/New Added/stay memorabel 03.jpeg";
// Section 7 
import Parasailing from "../Images/New Added/stay memorabel 02.jpeg";
import SwimmingPool from "../Images/Activities/Activities waterpark.webp";
import Fishing from "../Images/Activities/Activities Fishing.webp";
import RifleShooting from "../Images/Activities/Activities rifle.webp";
import ZipLine from "../Images/Activities/Activities Zipline.webp";
import RainDance from "../Images/Activities/Activities raindance.webp";
import Boating from "../Images/Activities/Activities boating.webp";
import Archery from "../Images/Activities/Activities archery.webp";
import IndoorOutdoorGames from "../Images/Activities/Activities Indoor games.webp";
import HammocksJhula from "../Images/Activities/Activitiesjhula.webp";
import Bonfire from "../Images/Activities/Activities campfire.webp";
import BurmahBridge from "../Images/Activities/Activities burmah_bridge.webp";
import NetClimbing from "../Images/Activities/NetClimbing.webp";
import kidsplayground from "../Images/Activities/Rubber tire Stock.webp"
// Homepage Resturant 

import HomeResturant from "../Images/Banner/Banner 5.webp";
import HomeResturant01 from "../Images/Restaurants/Restaurants08.webp";
import HomeResturant02 from "../Images/Restaurants/Restaurants06.webp";
import HomeResturant03 from "../Images/Restaurants/Restaurants01.webp";
import HomeResturant04 from "../Images/Restaurants/Restaurants02.webp";
import HomeResturant05 from "../Images/Restaurants/Restaurants03.webp";
import HomeResturant06 from "../Images/Restaurants/Restaurants04.webp";

import resortpackages from "../Images/resort packages.jpeg";
// swiper 

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import NavBarComponent from '../Components/NavBarComponent';
import Footer from '../Components/Footer';

import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import { FaTent } from "react-icons/fa6";
// Video 
import { MetaTags } from 'react-meta-tags';

import kittypartyrooms from '../Images/Lower Dormitory/IMG_3414.webp';
import FamilyCottageRoom from "../Images/Rooms/Family Cottage Room.webp";
import tent from "../Images/New Added/stay memorabel 01.jpeg";
import { Link } from 'react-router-dom';

import deluxesuiterooms from "../Images/Suite Room/GOPR0461.webp";
import familycottagerooms from "../Images/Family Cottage/GOPR0448.webp";
import ContainerHouse from "../Images/Container House/IMG_3138.webp";
import AClakeviewdormitory from "../Images/Upper Dormitory/IMG_3293.webp";
const Homepage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);


    // Automatically open and close the modal
    useEffect(() => {
        // Open modal on component mount
        setIsModalOpen(true);

        // Set a timeout to close the modal after 1 minute
        const timer = setTimeout(() => {
            setIsModalOpen(false);
        }, 60000);  // 60000 milliseconds = 1 minute

        // Cleanup the timer when the component unmounts
        return () => clearTimeout(timer);
    }, []);

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <>
            <MetaTags>
                <title>Best Resort in Karjat | Dahigaon Lake Resort</title>
                <meta title="Best Resort in Karjat | Dahigaon Lake Resort" />
                <meta name="description" content="Indulge in luxury at the Best Resort in Karjat. Immerse yourself in tranquility, surrounded by nature's beauty. Book your stay for a rejuvenating experience." />
                <meta name="keywords" content="Best Resort in Karjat,Best Lake Resort in Karjat,Resort in Karjat,Resort with Restaurant in Karjat,Lake View Resort in Karjat,Family Resort in Karjat,Top Lake Resort in Karjat" />
                <link rel="canonical" href="https://dahigaonlakeresortkarjat.in/" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content=" Best Resort in Karjat | Dahigaon Lake Resort" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dahigaonlakeresortkarjat.in/" />
                <meta property="og:description" content="Indulge in luxury at the Best Resort in Karjat. Immerse yourself in tranquility, surrounded by nature's beauty. Book your stay for a rejuvenating experience." />
                <meta property="og:image" content="https://dahigaonlakeresortkarjat.in/static/media/Main%20Logo.57a41dca45fe9f6df438.jpeg" />
            </MetaTags>


            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className='HomeSection1'>
                <div className='Home-banner'>
                    <NavBarComponent />
                    <div className='Home-banner-text'>
                        {/* <h1 className='d-none'>Best Resort in Karjat</h1> */}
                        <div className='HB-Text'>
                            <Zoom top>
                                <h1>Best Resort in Karjat</h1>
                            </Zoom>
                            <Fade bottom>
                                <p>Let the sea set you free.</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>


            {/* ////////////////////////////////////////// section 4 /////////////////////////////////////////// */}

            <section className='HomeSection4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='HS4-AboutUs-Images-con'>
                                <div className='HS4-AboutUs-Images'>
                                    <img src={leftOverlay} alt="Best Resort in Karjat" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='HS4-Aboutus'>
                                <div className='HS4-AboutUs-Title'>
                                    <Bounce left cascade>
                                        <h5>About Dahigaon Lake Resort</h5>
                                    </Bounce>
                                </div>
                                <div className='HS4-AboutUs-head'>
                                    <Flip left cascade>
                                        <h3>luxury and best service</h3>
                                        <h3>Resort in the town</h3>
                                    </Flip>

                                    <p>
                                        Dahigaon Lake Resort, nestled in the serene Dahigaon Village within the Karjat Taluka of Raigad District, stands as the epitome of tranquility and luxury. Renowned as the Best Resort in Karjat, this picturesque haven offers a sublime escape from the hustle and bustle of city life.
                                    </p>
                                    <p>
                                        As you enter Dahigaon Lake Resort, you are greeted by the breathtaking beauty of nature that surrounds the property. The resort boasts lush greenery, a pristine lake, and the majestic backdrop of the Karjat hills. It's a perfect blend of natural beauty and modern comfort, making it the ideal retreat for those seeking a peaceful getaway.
                                    </p>
                                </div>
                            </div>
                            <div className='HS4-fea'>
                                <div className='HS4-AboutUs-fea-detail'>
                                    <div className='HS4-pacefull-con'>
                                        <p>
                                            The Best Resort in Karjat, Dahigaon Lake Resort, prides itself on providing world-class amenities and top-notch services to its guests. The well-appointed rooms and suites offer a perfect blend of comfort and style, ensuring a relaxing stay. The resort's architecture seamlessly integrates with the natural landscape, creating a harmonious environment that enhances the overall guest experience.
                                        </p>
                                    </div>
                                    <div className='HS4-pacefull-con'>
                                        <p>
                                            For those with a penchant for adventure, Dahigaon Lake Resort offers a range of outdoor activities to choose from. Whether it's trekking in the Karjat hills, boating in the lake, or simply unwinding by the pool, guests can indulge in a variety of recreational pursuits. The resort's dedicated staff is always ready to assist in creating a personalized itinerary to make your stay truly memorable.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* ////////////////////////////////////////// section 3 /////////////////////////////////////////// */}

            <section className='HomeSection3'>
                <div className='container'>
                    <div className='HomeFacility'>
                        <div className='HomeFacility-title'>
                            <Slide top cascade>
                                <h3>Facility</h3>
                            </Slide>
                        </div>
                        <div className='HomeFacility-show'>
                            <div className='HomeFacility-show-Image'>
                                <img src={divider} alt="Best Lake Resort in Karjat" />
                            </div>
                        </div>

                        <div className='HomeFacility-qoutes'>
                            <Zoom>
                                <p>All Rooms include awesome amenities that will fit business to family or</p>
                                <p>Couple travel !</p>
                            </Zoom>
                        </div>
                    </div>

                    <div className='HomeFacility-Main'>
                        <div className='row row-cols-lg-5 row-cols-md-3 row-cols-2'>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <ImSpoonKnife />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>breakfast</h4>
                                        </Zoom>
                                    </div>

                                </div>

                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <FaWifi />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>free wifi</h4>
                                        </Zoom>
                                    </div>

                                </div>

                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <BsDisplay />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>TV</h4>
                                        </Zoom>
                                    </div>

                                </div>

                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <FaShower />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>shower</h4>
                                        </Zoom>
                                    </div>

                                </div>

                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <MdPool />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>pool</h4>
                                        </Zoom>
                                    </div>

                                </div>

                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <IoBed />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>a/c room</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <FaDharmachakra />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>Generator</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <FaTent />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>Tent</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <AiFillCar />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>Free Parking</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='HFacility-Con'>
                                    <div className='HFacility-icon'>
                                        <Zoom>
                                            <MdFoodBank />
                                        </Zoom>
                                    </div>
                                    <div className='HFacility-name'>
                                        <Zoom top cascade>
                                            <h4>restaurant</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////////// section 2 /////////////////////////////////// */}


            <section className="rooms-section pb-100">
                <div className="container">
                    <div
                        className="sec-title text-center wow fadeInUp animated rooms-section-head"
                        style={{ visibility: "visible", animationName: "fadeInUp" }}
                    >
                        <span className="sub-title">DAHIGAON LAKE RESORT</span>
                        <h2>Luxury Rooms &amp; Suites</h2>
                    </div>
                    <div className="row">
                        <div className="room-block col-lg-4 col-md-6 col-sm-12">
                            <div
                                className="inner-box wow fadeIn animated"
                                data-wow-delay="300ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "300ms",
                                    animationName: "fadeIn"
                                }}
                            >
                                <div className="image-box">
                                    <figure className="image-2 overlay-anim">
                                        <img
                                            src={deluxesuiterooms}
                                            alt="Resort in Karjat"
                                        />
                                    </figure>
                                </div>
                                <div className="content-box">
                                    <h6 className="title">
                                        <Link to="/best-resort">deluxe suite rooms</Link>
                                    </h6>
                                </div>
                                <div className="box-caption">
                                    <Link to="/best-resort" title="" className="book-btn">
                                        book now
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="room-block col-lg-4 col-md-6 col-sm-12">
                            <div
                                className="inner-box large-style wow fadeIn animated"
                                data-wow-delay="400ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "400ms",
                                    animationName: "fadeIn"
                                }}
                            >
                                <div className="image-box">
                                    <figure className="image-2 overlay-anim">
                                        <img
                                            src={familycottagerooms}
                                            alt="Resort with Restaurant in Karjat"
                                        />
                                    </figure>
                                </div>
                                <div className="content-box">
                                    <h6 className="title">
                                        <Link to="/best-resort">family cottage rooms</Link>
                                    </h6>
                                    {/* <span className="price">250$ / NIGHT</span> */}
                                </div>
                                <div className="box-caption">
                                    <Link to="/best-resort" title="" className="book-btn">
                                        book now
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="room-block col-lg-4 col-md-6 col-sm-12">
                            <div
                                className="inner-box wow fadeIn animated"
                                data-wow-delay="500ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "500ms",
                                    animationName: "fadeIn"
                                }}
                            >
                                <div className="image-box">
                                    <figure className="image-2 overlay-anim">
                                        <img
                                            src={ContainerHouse}
                                            alt="Lake View Resort in Karjat"
                                        />
                                    </figure>
                                </div>
                                <div className="content-box">
                                    <h6 className="title">
                                        <Link to="/best-resort">Container House</Link>
                                    </h6>
                                </div>
                                <div className="box-caption">
                                    <Link to="/best-resort" title="" className="book-btn">
                                        book now
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="room-block col-lg-4 col-md-6 col-sm-12">
                            <div
                                className="inner-box wow fadeIn animated"
                                data-wow-delay="500ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "500ms",
                                    animationName: "fadeIn"
                                }}
                            >
                                <div className="image-box">
                                    <figure className="image-2 overlay-anim">
                                        <img
                                            src={kittypartyrooms}
                                            alt="Lake View Resort in Karjat"
                                        />
                                    </figure>
                                </div>
                                <div className="content-box">
                                    <h6 className="title">
                                        <Link to="/best-resort">A/C kitty party rooms / dormitory</Link>
                                    </h6>
                                </div>
                                <div className="box-caption">
                                    <Link to="/best-resort" title="" className="book-btn">
                                        book now
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="room-block col-lg-4 col-md-6 col-sm-12">
                            <div
                                className="inner-box wow fadeIn animated"
                                data-wow-delay="500ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "500ms",
                                    animationName: "fadeIn"
                                }}
                            >
                                <div className="image-box">
                                    <figure className="image-2 overlay-anim">
                                        <img
                                            src={AClakeviewdormitory}
                                            alt="Lake View Resort in Karjat"
                                        />
                                    </figure>
                                </div>
                                <div className="content-box">
                                    <h6 className="title">
                                        <Link to="/best-resort">A/C lake view dormitory</Link>
                                    </h6>
                                </div>
                                <div className="box-caption">
                                    <Link to="/best-resort" title="" className="book-btn">
                                        book now
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="room-block col-lg-4 col-md-6 col-sm-12">
                            <div
                                className="inner-box wow fadeIn animated"
                                data-wow-delay="500ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "500ms",
                                    animationName: "fadeIn"
                                }}
                            >
                                <div className="image-box">
                                    <figure className="image-2 overlay-anim">
                                        <img
                                            src={FamilyCottageRoom}
                                            alt="Lake View Resort in Karjat"
                                        />
                                    </figure>
                                </div>
                                <div className="content-box">
                                    <h6 className="title">
                                        <Link to="/best-resort">Tents</Link>
                                    </h6>
                                </div>
                                <div className="box-caption">
                                    <Link to="/best-resort" title="" className="book-btn">
                                        book now
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* ////////////////////////////////////////// section 5 /////////////////////////////////////////// */}

            < section className='HomeSection5' >
                <div className='HomeSection5-head-info'>
                    <div className='HS5-main-title'>
                        <Flip left cascade>
                            <h4>Make your stay</h4>
                            <h4>memorabel</h4>
                        </Flip>
                    </div>
                    <div className='HomeFacility-show'>
                        <div className='HomeFacility-show-Image'>
                            <img src={divider} alt="Family Resort in Karjat" />
                        </div>
                    </div>
                    <div className='HS5-main-title-para'>
                        <Bounce left cascade>
                            <p>Best moment of your life</p>
                        </Bounce>
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            {/* <Zoom>
                                <div className='HS5-left-Image'>
                                    <video className='videoTag' autoPlay loop muted>
                                        <source src={Video} type='video/mp4' />
                                    </video>
                                </div>
                            </Zoom> */}
                            <div className='HS5-left'>
                                {/* <div className='HS5-with-partner'>
                                    <h4>
                                        Enjoy with your family
                                    </h4>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit nam ea, architecto commodi vero molestias praesentium repellendus quas ratione tempora veritatis reiciendis culpa blanditiis ullam rem consequuntur, nisi exercitationem. Soluta.
                                    </p>
                                </div>
                                <div className='HS5-with-friends'>
                                    <h4>
                                        Enjoy with your friends
                                    </h4>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit nam ea, architecto commodi vero molestias praesentium repellendus quas ratione tempora veritatis reiciendis culpa blanditiis ullam rem consequuntur, nisi exercitationem. Soluta.
                                    </p>
                                </div> */}

                                <div className='HS5-left-Image-con'>
                                    <img src={tent} alt="Top Lake Resort in Karjat" />
                                </div>

                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='HS5-right'>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="aboutImg firstImage">
                                            <img src={Parasailing} alt="near karjat resort" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="aboutImg">
                                            <img src={EnjoyFriend1} alt="Lake Resort" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="aboutImg thirdImage">
                                            <img src={EnjoyFriend2} alt="Lake Resort near me" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="aboutImg lastImage">
                                            <img src={Enjoypartner2} alt="top near karjat resort" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* ///////////////////////////////////// restaurant Section ///////////////////////////////////// */}

            < section className='ResturantSection2' >
                <div className='container'>
                    <div className='Resturant-Details'>
                        <div className='Resturant-Title'>
                            <Flip left cascade>
                                <h3>Dahigaon Lake Resort</h3>
                                <p>ONE OF THE BEST PLACES TO TASTE.. ANYTHING</p>
                                <h2>resturant</h2>
                            </Flip>
                        </div>
                        <div className='Showcon'>
                            <div className='Show-Image'>
                                <img src={ShowImage01} alt="Karjat resturant in resort" />
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className='EventSection2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Slide left>
                                <div className='HomeResImage'>
                                    <Swiper
                                        spaceBetween={30}
                                        centeredSlides={true}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide>
                                            <div className='EventImages'>
                                                <img src={HomeResturant} alt="Best Resort in Karjat" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='EventImages'>
                                                <img src={HomeResturant01} alt="Best Resort in Karjat" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='EventImages'>
                                                <img src={HomeResturant02} alt="Best Resort in Karjat" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='EventImages'>
                                                <img src={HomeResturant03} alt="Best Resort in Karjat" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='EventImages'>
                                                <img src={HomeResturant04} alt="Best Resort in Karjat" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='EventImages'>
                                                <img src={HomeResturant05} alt="Best Resort in Karjat" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='EventImages'>
                                                <img src={HomeResturant06} alt="Best Resort in Karjat" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-6'>
                            <div className='Events_details-Con'>
                                <Slide right>
                                    <div className='Events-Info'>
                                        <div className='Events-text'>
                                            <h3>WE GUARANTEE</h3>
                                            <h2>A REMARKABLE EXPERIENCE</h2>
                                            <p>Our Family Cottage Room is comfortably furnished with a queen-size bed and an attached bathroom featuring a shower enclosure with a handheld shower. The room also has free high-speed wireless Internet access and ample closet space for storing suitcases or hanging clothing for guests' convenience.</p>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </section>

            {/* ///////////////////////////////////////////////////////////// */}

            <section className="ourSocialLink">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 d-flex justify-content-start align-items-end">
                            <div className="socialText">
                                <h2>follow Us</h2>
                                <p>Know more about us</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 d-flex justify-content-end align-items-end">
                            <ul className="socialItems">
                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=100064186357674" target="_blank">
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth={0}
                                            viewBox="0 0 24 24"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M9.94474914,22 L9.94474914,13.1657526 L7,13.1657526 L7,9.48481614 L9.94474914,9.48481614 L9.94474914,6.54006699 C9.94474914,3.49740494 11.8713513,2 14.5856738,2 C15.8857805,2 17.0033128,2.09717672 17.3287076,2.13987558 L17.3287076,5.32020466 L15.4462767,5.32094085 C13.9702212,5.32094085 13.6256856,6.02252733 13.6256856,7.05171716 L13.6256856,9.48481614 L17.306622,9.48481614 L16.5704347,13.1657526 L13.6256856,13.1657526 L13.6845806,22"
                                            />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/dahigaonlake/?igshid=MDJmNzVkMjY%3D" target="_blank">
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth={0}
                                            viewBox="0 0 1024 1024"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href=""
                                        target="_blank"
                                    >
                                        <RiWhatsappLine />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>



            {/* ////////////////////////////////////////// section 7 /////////////////////////////////////////// */}

            <section className='HomeSection7'>
                <div className='HomeSection7-head-info'>
                    <div className='HS7-main-title'>
                        <Flip left cascade>
                            <h4>Near By activity</h4>
                        </Flip>
                    </div>
                </div>
                <div className='container'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                    >

                        <SwiperSlide>
                            <div className="Hotel-image-wrapper HS7firstImage ">
                                <img src={SwimmingPool} alt="Swimming Pool" />
                                <div className="Hotel-img-overlay">
                                    <h3>Swimming Pool</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper">
                                <img src={RainDance} alt="Rain Dance" />
                                <div className="Hotel-img-overlay">
                                    <h3>Rain Dance</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper HS7firstImage ">
                                <img src={Boating} alt="Boating" />
                                <div className="Hotel-img-overlay">
                                    <h3>Boating</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper">
                                <img src={Fishing} alt="Fishing" />
                                <div className="Hotel-img-overlay">
                                    <h3>Fishing</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper HS7firstImage">
                                <img src={ZipLine} alt="Zipline" />
                                <div className="Hotel-img-overlay">
                                    <h3>Zipline</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper">
                                <img src={RifleShooting} alt="Rifle Shooting" />
                                <div className="Hotel-img-overlay">
                                    <h3>Rifle Shooting</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper HS7firstImage">
                                <img src={Archery} alt="Archery" />
                                <div className="Hotel-img-overlay">
                                    <h3>Archery</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper">
                                <img src={IndoorOutdoorGames} alt="Indoor/Outdoor Games" />
                                <div className="Hotel-img-overlay">
                                    <h3>Indoor/Outdoor Games</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper HS7firstImage">
                                <img src={kidsplayground} alt="Kids Playground" />
                                <div className="Hotel-img-overlay">
                                    <h3>Kids Playground</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper">
                                <img src={Bonfire} alt="Bonfire" />
                                <div className="Hotel-img-overlay">
                                    <h3>Bonfire</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper HS7firstImage">
                                <img src={HammocksJhula} alt="Hammocks (Jhula)" />
                                <div className="Hotel-img-overlay">
                                    <h3>Hammocks (Jhula)</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper">
                                <img src={NetClimbing} alt="Net Climbing" />
                                <div className="Hotel-img-overlay">
                                    <h3>Net Climbing</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="Hotel-image-wrapper HS7firstImage">
                                <img src={BurmahBridge} alt="Burmah Bridge" />
                                <div className="Hotel-img-overlay">
                                    <h3>Burmah Bridge</h3>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section >

            {/* ///////////////////////////////////////////  review dection //////////////////////////////////////////// */}

            <section className="cutomerReview" id="cutomerReview">
                <div className='container'>
                    <ReviewsSwaiper />
                </div>
            </section>

            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className='Home-modal-popup'>
                    <img src={resortpackages} alt='resort packages'/>
                </div>
            </Modal>

            <Footer />




        </>
    )
}

export default Homepage;    
