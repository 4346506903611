

const ReviewsComponent = [
    {
        id: 1,
        Name: "Anmol Deshpande",
        Description: "Good Resort for Family vacation. Swimming pool n amenities are nice, food was excellent and on request they arranged for special (non spicy) food for kid.Lake activities, zip line,riverwalk were awsome.staff was polite n friendly.Needs improvement in AC functioning during summer( afternoon)..Good value for money.",
    },
    {
        id: 2,
        Name: "Pradeep Kulkarni",
        Description: "Nice place, pleasant atmosphere, good food quality. Main thing they are booking less groups at a time so can provide more attention to guests. Total satisfaction of spend money......",
    },
    {
        id: 3,
        Name: "dilip",
        Description: "We thoroughly enjoyed our stay. all staffs were always motivated to help us. The food was excellent, it was lovely & very serene. The rides were awesome. We look forward to coming back. Feel very revitalized after a relaxing stay here.. Very much recommended.",
    },
    {
        id: 4,
        Name: "Santosh Waydande",
        Description: "We thoroughly enjoyed our stay. The activities were interesting. Thanks to staffs who were always there to guide and help us. The food was excellent, the ambience was lovely & very serene. I personally look forward to coming back.Special thanks to deshmukh kaka..!!",
    },
    {
        id: 5,
        Name: "Rachna Lachke",
        Description: "Best place for a break you need. Beautiful nature along with Adventure Sports like Zipline. Food Quality & Taste Very Good and also their service.Deshmukh Sir and other people's hospitality is superb.",
    },
    {
        id: 6,
        Name: "Palkhi Jadhav",
        Description: "The stay was full enjoyable. Feeling very relaxed after coming back form there.There are many activities available there like zip lining, baoting the staff motivated as to do. We had best experience over there. The staff is very friendly. We will visit again we'll recommend to everyone",
    },
    {
        id: 7,
        Name: "Aphula Lims",
        Description: "It was an awesome experience at Dahigaon Lake Resort, the view and the scenery was beautiful and the staffs were very helpful and kind.I enjoyed so much and was a satisfying trip.",
    },
    {
        id: 8,
        Name: "Sonu Pradhan",
        Description: "The stay was fabulous and the food was amazing. There are many recreational facilities available.",
    },
    {
        id: 9,
        Name: "Abhishek poovil",
        Description: "Great place with great ambiance & the staff is also very polite and helpful. Awesome place to visit with friends and family.",
    },
    {
        id: 10,
        Name: "Ashok Kanekar",
        Description: "We were group of 13 people went to this resort and had amazing experience for one night stay.USP of the resort is beautiful late and complimentary zip line and boating...Staff was accomodative and politeFood was good but could be betterSurely recommend this resort if you are planning for one night with large family group or with friends group...it will be worth..",
    },
    {
        id: 11,
        Name: "Bhagyoday Nimbu",
        Description: "We were 60 people for a birthday celebration. staff were amazing. Service was excellent. The owners of a resort Mr. Prakash Deshmukh and Mrs. Kavita Deshmukh both were so supportive and amazing people. They provided the best service.",
    },
]


export default ReviewsComponent;