
import './App.css';
import NavBarComponent from './Components/NavBarComponent';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Homepage from './Pages/Homepage';
import AccommodationPage from './Pages/AccommodationPage';
import EventPage from './Pages/EventPage';
import Imagegallery from './Pages/Imagegallery';
import AboutUsPage from './Pages/AboutUsPage';
import ContactUs from './Pages/ContactUs';
import Restaurants from './Pages/Restaurants';
import ErrorPage from "./Components/Error Page/ErrorPage"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <NavBarComponent /> */}
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/best-lake-resort-karjat' element={<EventPage />} />
          <Route path='/resort-karjat' element={<AccommodationPage />} />
          <Route path='/lake-view-resort-karjat' element={<Imagegallery/>}/>
          <Route path='/family-resort-karjat' element={<AboutUsPage/>}/>
          <Route path='/top-lake-resort-karjat' element={<ContactUs/>}/>
          <Route path='/resort-restaurant-karjat' element={<Restaurants/>}/>
          <Route path='/*' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
