import React from 'react';
import "../CSS/Footer.css";
import Logo from "../Images/Main Logo.jpeg";
import { GrFacebookOption } from 'react-icons/gr';
import { AiFillInstagram } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io5';
import { BsYoutube } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 footer-contact">
                                <Link to="/"> <img src={Logo} alt="company logo" /></Link>
                                <p>
                                    <a style={{ color: "#fff" }} href="tel:+91 8898999989">

                                        <strong>Phone:</strong> 889 899 9989
                                    </a>
                                    <br />
                                    <a
                                        style={{ color: "#fff" }}
                                        href="mailto:pvdgroup@gmail.com"
                                    >
                                        <strong>Email:</strong>pvdgroup@gmail.com
                                    </a>
                                    <br />
                                </p>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li>
                                        <i className="fa fa-chevron-right" /> <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        <Link to="/best-lake-resort-karjat">Events</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        <Link to="/resort-karjat">Accommodation</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Quick Link</h4>
                                <ul>
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        <Link to="/lake-view-resort-karjat"> Gallery</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        <Link to="/family-resort-karjat">About Us</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" />
                                        <Link to="/top-lake-resort-karjat">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Social Networks</h4>
                                <div className="social-links mt-3">
                                    <a
                                        href="https://www.facebook.com/profile.php?id=100064186357674"
                                        className="facebook"
                                        target="_blank"
                                    >
                                        <GrFacebookOption />
                                    </a>
                                    <a
                                        href="https://www.instagram.com/dahigaonlake/?igshid=MDJmNzVkMjY%3D"
                                        className="instagram"
                                        target="_blank"
                                    >
                                        <AiFillInstagram />
                                    </a>
                                    <a
                                        href="https://wa.me/8898999989"
                                        className="youtube"
                                        target="_blank"
                                    >
                                        <IoLogoWhatsapp />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container footer-bottom clearfix">
                    <div className="copyright">
                        © Copyright
                        <strong>
                            <span style={{ color: "#d6a063" }}>Dahigaon Lake Resort</span>
                        </strong>
                        . All Rights Reserved
                    </div>
                    <div className="credits">
                        Designed by
                        <a href="https://skdm.in/" style={{ color: "#d6a063" }}>
                            Shree Krishna Digital
                        </a>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer