import React, { useLayoutEffect } from 'react';
import NavBarComponent from '../Components/NavBarComponent';
import "../CSS/AboutUS.css";


// section 2 Images 
import leftOverlay from "../Images/Banner/rainbow.webp";
import Footer from "../Components/Footer";

import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import LightSpeed from 'react-reveal/LightSpeed';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

import ABImage01 from "../Images/Gallery/Gallery 14.webp";
import ABImage02 from "../Images/Gallery/Gallery 10.webp";
import ABImage03 from "../Images/Gallery/Gallery 28.webp";
import ABImage04 from "../Images/Banner/lake.webp";
import { MetaTags } from 'react-meta-tags';

const AboutUsPage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>

            <MetaTags>
                <title>Family Resort in Karjat | Dahigaon Lake Resort</title>
                <meta title="Family Resort in Karjat | Dahigaon Lake Resort" />
                <meta name="description" content="Forge lasting connections at our Family Resort in Karjat. Immerse yourselves in fun activities, enjoy comfortable accommodations & make cherished memories." />
                <meta name="keywords" content="Best Resort in Karjat,Best Lake Resort in Karjat,Resort in Karjat,Resort with Restaurant in Karjat,Lake View Resort in Karjat,Family Resort in Karjat,Top Lake Resort in Karjat" />
                <link rel="canonical" href="https://dahigaonlakeresortkarjat.in/family-resort-karjat" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content=" Family Resort in Karjat | Dahigaon Lake Resort" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dahigaonlakeresortkarjat.in/" />
                <meta property="og:description" content="Forge lasting connections at our Family Resort in Karjat. Immerse yourselves in fun activities, enjoy comfortable accommodations & make cherished memories." />
                <meta property="og:image" content="https://dahigaonlakeresortkarjat.in/static/media/Main%20Logo.57a41dca45fe9f6df438.jpeg" />
            </MetaTags>


            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className='AboutUsSection1'>
                <div className='AboutUs-banner'>
                    <NavBarComponent />
                    <div className='AboutUs-banner-text'>
                        {/* <Slide left>
                        <div className='Text'>
                            <h4>An actor has to burn inside with an outer ease.</h4>
                            </div>
                        </Slide> */}
                        {/* <h1 className='d-none'>Family Resort in Karjat</h1> */}
                        <div className='ABS1-Text'>
                            <Zoom top>
                                <h1>Family Resort in Karjat</h1>
                            </Zoom>
                            <Fade bottom>
                                <p>It's Bad Manner To Keep A Vacation Wating</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>


            {/* /////////////////////////////// section 2 /////////////////////////////////// */}



            <section className='HomeSection4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='HS4-AboutUs-Images-con'>
                                <div className='HS4-AboutUs-Images'>
                                    <img src={leftOverlay} alt="Dahigaon family Lake Resort" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='HS4-Aboutus'>
                                <div className='HS4-AboutUs-Title'>
                                    <Bounce left cascade>
                                        <h5>About Dahigaon Lake Resort</h5>
                                    </Bounce>
                                </div>
                                <div className='HS4-AboutUs-head'>
                                    <Flip left cascade>
                                        <h3>luxury and best service</h3>
                                        <h3>Resort in the town</h3>
                                    </Flip>

                                    <p>
                                        Dahigaon Lake Resort, nestled in the serene Dahigaon Village within the Karjat Taluka of Raigad District, stands as the epitome of tranquility and luxury. Renowned as the Best Resort in Karjat and a premier Family Resort in Karjat, this picturesque haven offers a sublime escape from the hustle and bustle of city life.
                                    </p>
                                    <p>
                                        As you enter Dahigaon Lake Resort, you are greeted by the breathtaking beauty of nature that surrounds the property. The resort boasts lush greenery, a pristine lake, and the majestic backdrop of the Karjat hills. It's a perfect blend of natural beauty and modern comfort, making it the ideal retreat for those seeking a peaceful family getaway.
                                    </p>
                                </div>
                            </div>
                            <div className='HS4-fea'>
                                <div className='HS4-AboutUs-fea-detail'>
                                    <div className='HS4-pacefull-con'>
                                        <p>
                                            The Best Resort in Karjat and Family Resort in Karjat, Dahigaon Lake Resort, prides itself on providing world-class amenities and top-notch services to its guests. The well-appointed family-friendly rooms and suites offer a perfect blend of comfort and style, ensuring a relaxing stay.
                                        </p>
                                    </div>
                                    <div className='HS4-pacefull-con'>
                                        <p>
                                            For those with a penchant for adventure, Dahigaon Lake Resort offers a range of family-friendly outdoor activities to choose from. Whether it's trekking in the Karjat hills, boating in the lake, or simply unwinding by the pool, families can indulge in a variety of recreational pursuits.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >



            {/* /////////////////////////////// section 3 /////////////////////////////////// */}

            <section className='ABoutUsSection'>
                <div className='container'>
                    <div className='villainfo'>
                        <LightSpeed left cascade>
                            <h4>About Dahigaon Lake Resort</h4>
                        </LightSpeed>
                        <Zoom>
                            <p>
                                Welcome to Dahigaon Lake Resort, a captivating retreat in the heart of nature. As a premier Family Resort in Karjat, our haven blends tranquility with luxury, providing an idyllic escape for families seeking cherished moments amidst lush greenery, a pristine lake, and the majestic Karjat hills. Immerse yourself in world-class amenities, family-friendly accommodations, and a harmonious environment, ensuring an unforgettable experience for every member of your family. Dahigaon Lake Resort invites you to create lasting memories in a space where comfort meets the beauty of Karjat's landscapes.</p>
                        </Zoom>
                    </div>


                    <div className='AboutVillaImages'>
                        <div className='row'>
                            <div className='col-md-6 col-sm-6 col-lg-3'>
                                <div className='VillaImage'>
                                    <img src={ABImage04} alt="family resort in karjat" />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-6 col-lg-3'>
                                <div className='VillaImage'>
                                    <img src={ABImage01} alt="resort in karjat" />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-6 col-lg-3'>
                                <div className='VillaImage'>
                                    <img src={ABImage03} alt="resort for family in karjat" />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-6 col-lg-3'>
                                <div className='VillaImage'>
                                    <img src={ABImage02} alt="lake view resort for famliy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default AboutUsPage;