import React, { useLayoutEffect, useRef, useState } from "react";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import NavBarComponent from '../Components/NavBarComponent';
import "../CSS/Restaurants.css";

import offerimage1 from "../Images/Dish/Breakfast.png";
import offerimage2 from "../Images/Dish/Lunch.png";
import offerimage3 from "../Images/Dish/Dinner.png";

import AnimatedImage01 from "../Images/Restaurants/bg-2.png";
import AnimatedImage02 from "../Images/Restaurants/bg-1.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import FourDish from "../Images/Dish/AboutUS.png";

import Footer from "../Components/Footer";
import { MetaTags } from 'react-meta-tags';

import Food01 from "../Images/Food/Food 01.webp";
import Food02 from "../Images/Food/Food 02.webp";

const Restaurants = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <MetaTags>
                <title>Resort with Restaurant in Karjat | Dahigaon Lake Resort</title>
                <meta title="Resort with Restaurant in Karjat | Dahigaon Lake Resort" />
                <meta name="description" content="Savor the perfect blend of relaxation and gastronomy at our Resort with a Restaurant in Karjat. Indulge in delicious cuisine amidst nature's beauty." />
                <meta name="keywords" content="Best Resort in Karjat,Best Lake Resort in Karjat,Resort in Karjat,Resort with Restaurant in Karjat,Lake View Resort in Karjat,Family Resort in Karjat,Top Lake Resort in Karjat" />
                <link rel="canonical" href="https://dahigaonlakeresortkarjat.in/resort-restaurant-karjat" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content=" Resort with Restaurant in Karjat | Dahigaon Lake Resort" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dahigaonlakeresortkarjat.in/" />
                <meta property="og:description" content="Savor the perfect blend of relaxation and gastronomy at our Resort with a Restaurant in Karjat. Indulge in delicious cuisine amidst nature's beauty." />
                <meta property="og:image" content="https://dahigaonlakeresortkarjat.in/static/media/Main%20Logo.57a41dca45fe9f6df438.jpeg" />
            </MetaTags>
            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className='RestaurantSection1'>
                <div className='Restaurant-banner'>
                    <NavBarComponent />
                    <div className='Restaurant-banner-text'>
                        {/* <h1 className="d-none">Resort with Restaurant in Karjat</h1> */}
                        <div className='AS1-Text'>
                            <Zoom top>
                                <h1>Resort with Restaurant in Karjat</h1>
                            </Zoom>
                            <Fade bottom>
                                <p>We make planning easy, so you can focus on celebrating!</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>


            {/* /////////////////////////////// section 4 /////////////////////////////////// */}

            <section className="RestaurantsSection4">
                <div className="container">
                    <div className="RS-4-deatils">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="RS-4-Image-con">
                                    <div className="RS-4-Image">
                                        <img src={FourDish} alt="resort in karjat" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="RS-4-Details">
                                    <div className="RS-Info">
                                        <h5>About Restaurant</h5>
                                        <h2>Traditional & Modern Service</h2>
                                        <p>

                                            Discover the epitome of serenity and luxury at our Resort with Restaurant in Karjat. Nestled amidst nature's beauty, our resort offers a tranquil escape from the hustle and bustle of city life. With well-appointed accommodations, breathtaking views, and a range of recreational activities, our Resort with Restaurant in Karjat promises an unforgettable retreat. Whether you seek adventure or relaxation, our resort with restaurant is your haven in the heart of Karjat's scenic landscapes. Experience the charm and hospitality of our Resort with Restaurant in Karjat for an unparalleled getaway, where every moment is complemented by the culinary delights offered in our on-site restaurant.                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////////// section 2 /////////////////////////////////// */}

            <section className='RestaurantsSection2'>
                <div className='RestaurantsSection2-con'>
                    <div className='container'>
                        <div className='row'>

                            <div className='col-md-4 order-2 order-sm-1'>
                                <div className='Breakfast-con'>
                                    <div className='Breakfast-mid'>
                                        <div className='Breakfast'>
                                            <img src={offerimage1} alt="Restaurant Resort in Karjat" />
                                        </div>
                                        <div className='Breakfast-Name'>
                                            <h5>Breakfast</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4 order-1  order-sm-2'>
                                <div className='Breakfast-con'>
                                    <div className='Breakfast-mid'>
                                        <div className='Breakfast-Details-con'>
                                            <h5>FLAVORS FOR ROYALTY</h5>
                                            <h2>We Offer Top Notch</h2>
                                            <p>


                                                Indulge in a world of luxury and serenity at our Resort with Restaurant in Karjat. Experience top-notch amenities, scenic beauty, and unparalleled hospitality, creating an unforgettable retreat in the heart of nature's embrace. Enhance your stay with delectable dining experiences offered at our on-site restaurant, making every moment a harmonious blend of indulgence and tranquility.
                                            </p>
                                        </div>
                                        <div className='Breakfast-Image'>
                                            <div className='Breakfast'>
                                                <img src={offerimage2} alt="Restaurant in Karjat" />
                                            </div>
                                        </div>
                                        <div className='Breakfast-Name'>
                                            <h5>Lunch</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4 order-3 order-sm-3'>
                                <div className='Breakfast-con'>
                                    <div className='Breakfast-mid'>
                                        <div className='Breakfast'>
                                            <img src={offerimage3} alt="Resort Restaurant in Karjat" />
                                        </div>
                                        <div className='Breakfast-Name'>
                                            <h5>Dinner</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Animated-Image-con1'>
                    <div className='Animated-Image1'>
                        <img src={AnimatedImage01} alt="Best Restaurant Resort in Karjat" />
                    </div>
                </div>
                <div className='Animated-Image-con2'>
                    <div className='Animated-Image2'>
                        <img src={AnimatedImage02} alt="Best Lake Resort Restaurant in Karjat" />

                    </div>

                </div>
            </section>

            {/* ///////////////////////////////////////////////////////////////// */}

            {/* /////////////////////////////// section 3 /////////////////////////////////// */}
            <section className='RestaurantsSection3'>
                <div className='container'>
                    <div className='RS3-Details'>
                        <h6>SPECIAL OFFER</h6>
                        <h2>Best Specialties</h2>
                    </div>
                    <div className='RS3-Swaiper'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="Swiper-con">
                                    <div className="Swaiper-Image">
                                        <img src={Food01} alt="nonveg resort restaurant in karjat" />
                                    </div>
                                    <div className="Swaiper-text">
                                        <h4>Fish</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="Swiper-con">
                                    <div className="Swaiper-Image">
                                        <img src={Food02} alt="veg resort restaurant in karjat" />
                                    </div>
                                    <div className="Swaiper-text">
                                        <h4>Fish</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Restaurants