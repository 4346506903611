import React, { useLayoutEffect } from 'react';
import "../CSS/Events.css";
import NavBarComponent from '../Components/NavBarComponent';
import BachelorParty from "../Images/Bachelor Party.jpg";
import OfficeParty from "../Images/Company-party.jpg";
import Birthdayparty from "../Images/Birthday-Party.jpg";
import WeddingEvents from "../Images/Wedding Events/Wedding Events 04.jpeg"
import newyear from "../Images/New Year 2023/New Year 05.webp"
import Footer from "../Components/Footer";

import Slide from 'react-reveal/Slide';
import LightSpeed from 'react-reveal/LightSpeed';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

import { MetaTags } from 'react-meta-tags';



const EventPage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>

            <MetaTags>
                <title>Best Lake Resort in Karjat | Dahigaon Lake Resort</title>
                <meta title="Best Lake Resort in Karjat | Dahigaon Lake Resort" />
                <meta name="description" content="Experience serenity at the Best Lake Resort in Karjat. Nestled in nature's embrace, enjoy picturesque views and luxurious amenities. Book your retreat Now!!" />
                <meta name="keywords" content="Best Resort in Karjat,Best Lake Resort in Karjat,Resort in Karjat,Resort with Restaurant in Karjat,Lake View Resort in Karjat,Family Resort in Karjat,Top Lake Resort in Karjat" />
                <link rel="canonical" href="https://dahigaonlakeresortkarjat.in/best-lake-resort-karjat" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Best Lake Resort in Karjat | Dahigaon Lake Resort" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dahigaonlakeresortkarjat.in/" />
                <meta property="og:description" content="Experience serenity at the Best Lake Resort in Karjat. Nestled in nature's embrace, enjoy picturesque views and luxurious amenities. Book your retreat Now!!" />
                <meta property="og:image" content="https://dahigaonlakeresortkarjat.in/static/media/Main%20Logo.57a41dca45fe9f6df438.jpeg" />
            </MetaTags>

            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className='EventSection1'>
                <div className='Event-banner'>
                    <NavBarComponent />
                    <div className='Event-banner-text'>
                        {/* <h1 className='d-none'>Best Lake Resort in Karjat</h1> */}
                        <div className='ES1-Text'>
                            <Zoom top>
                                <h1>Best Lake Resort in Karjat</h1>
                            </Zoom>
                            <Fade bottom>
                                <p>We make planning easy, so you can focus on celebrating!</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////////// section 2 /////////////////////////////////// */}


            <div className='EventPage-title'>
                <h2>
                    <LightSpeed left cascade>
                        MAKE YOUR SPECIAL DAY
                        MEMORABEL
                    </LightSpeed>
                </h2>
            </div>
            <section className='EventSection2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 order-md-1 order-2'>
                            <Slide left>
                                <div className='EventImageCon'>
                                    <div className='EventImages'>
                                        <img src={Birthdayparty} alt="Birthday Party in karjat" />
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-6 order-md-2 order-1'>
                            <Slide right>
                                <div className='Events_details-Con'>
                                    <div className='Events-Info'>
                                        <div className='Events-text'>
                                            <h3>LOVE, LAUGHTER & HAPPILY EVER AFTER!</h3>
                                            <h2>Birthday Party</h2>
                                            <p>
                                                Celebrate your special day at the Best Lake Resort in Karjat, where we redefine birthdays with unique cake designs, personalized favors, a delectable all-age menu, and entertaining games. Whether it's a first birthday or a milestone celebration, our exceptional facility ensures a memorable event reflecting the charm of Karjat's lakeside surroundings. With attention to detail and a commitment to personalized service, our resort guarantees an extraordinary birthday experience, creating cherished moments that linger long after the celebration concludes.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </section>



            {/* /////////////////////////////// section 3 /////////////////////////////////// */}

            <section className='EventSection3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Slide left>
                                <div className='Events_details-Con'>
                                    <div className='Events-Info'>
                                        <div className='Events-text'>
                                            <h3>LOVE, LAUGHTER & HAPPILY EVER AFTER!</h3>
                                            <h2>Office Party</h2>
                                            <p>Opt for the Best Lake Resort in Karjat as your event venue, perfectly suited for corporate parties. With an expansive and impressive space that exceeds the offerings of commonplace locations such as hotel conference rooms or chain restaurant dining rooms, our resort provides a service level tailored to any occasion. Whatever your event, choose our venue for a distinctive and memorable experience.</p>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>

                        <div className='col-md-6'>
                            <Slide right>
                                <div className='EventImageCon'>
                                    <div className='EventImages'>
                                        <img src={OfficeParty} alt="Office Party in karjat" />
                                    </div>
                                </div>
                            </Slide>
                        </div>

                    </div>
                </div>
                <hr></hr>
            </section>



            {/* /////////////////////////////// section 4 /////////////////////////////////// */}

            <section className='EventSection4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 order-md-1 order-2'>
                            <Slide left>
                                <div className='EventImageCon'>
                                    <div className='EventImages'>
                                        <img src={BachelorParty} alt="Family Party in karjat" />
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-6 order-md-2 order-1'>
                            <Slide right>
                                <div className='Events_details-Con'>
                                    <div className='Events-Info'>
                                        <div className='Events-text'>
                                            <h3>LOVE, LAUGHTER & HAPPILY EVER AFTER!</h3>
                                            <h2>Family Party</h2>
                                            <p>
                                                Embark on a memorable pre-wedding celebration at the Best Lake Resort in Karjat! As you count down your final days of singlehood, Dahigaon Lake Resort is here to add an element of fun and excitement to your wedding festivities. Congratulations on tying the knot, and let us make your celebration truly special!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </section>

            {/* /////////////////////////////// section 2 /////////////////////////////////// */}

            <section className='EventSection3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Slide left>
                                <div className='Events_details-Con'>
                                    <div className='Events-Info'>
                                        <div className='Events-text'>
                                            <h3>LOVE, LAUGHTER & HAPPILY EVER AFTER!</h3>
                                            <h2>Wedding Events</h2>
                                            <p>
                                                Celebrate your love story at the Best Lake Resort in Karjat – Dahigaon Lake Resort. Our picturesque venue offers an enchanting backdrop for your wedding events. From the ceremony to the reception, our resort ensures a magical experience with its stunning lakeside charm. Let us make your special day extraordinary amidst the tranquility and beauty of Karjat's best lake resort.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>

                        <div className='col-md-6'>
                            <Slide right>
                                <div className='EventImageCon'>
                                    <div className='EventImages'>
                                        <img src={WeddingEvents} alt="Wedding Events in karjat" />
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </section>

            {/* /////////////////////////////// section 4 /////////////////////////////////// */}

            <section className='EventSection4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 order-md-1 order-2'>
                            <Slide left>
                                <div className='EventImageCon'>
                                    <div className='EventImages'>
                                        <img src={newyear} alt="Family Party in karjat" />
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-6 order-md-2 order-1'>
                            <Slide right>
                                <div className='Events_details-Con'>
                                    <div className='Events-Info'>
                                        <div className='Events-text'>
                                            <h3>LOVE, LAUGHTER & HAPPILY EVER AFTER!</h3>
                                            <h2>New Year 2022</h2>
                                            <p>
                                                Embark on a memorable pre-wedding celebration at the Best Lake Resort in Karjat! As you count down your final days of singlehood, Dahigaon Lake Resort is here to add an element of fun and excitement to your wedding festivities. Congratulations on tying the knot, and let us make your celebration truly special!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </section>


            <Footer />

        </>
    )
}

export default EventPage

