import React, { useLayoutEffect } from 'react';
import "../CSS/Accommodation.css";
import NavBarComponent from '../Components/NavBarComponent';
import Footer from "../Components/Footer";
import Zoom from 'react-reveal/Zoom';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';
import { MetaTags } from 'react-meta-tags';
import Rooms from '../Components/Rooms';


const AccommodationPage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <MetaTags>
                <title> Resort in Karjat | Dahigaon Lake Resort</title>
                <meta title=" Resort in Karjat | Dahigaon Lake Resort" />
                <meta name="description" content="Embark on a journey of relaxation and luxury at our Resort in Karjat. Immerse yourself in nature's beauty, enjoy modern amenities, and create lasting memories." />
                <meta name="keywords" content="Best Resort in Karjat,Best Lake Resort in Karjat,Resort in Karjat,Resort with Restaurant in Karjat,Lake View Resort in Karjat,Family Resort in Karjat,Top Lake Resort in Karjat" />
                <link rel="canonical" href="https://dahigaonlakeresortkarjat.in/resort-karjat" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content=" Resort in Karjat | Dahigaon Lake Resort" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dahigaonlakeresortkarjat.in/" />
                <meta property="og:description" content="Embark on a journey of relaxation and luxury at our Resort in Karjat. Immerse yourself in nature's beauty, enjoy modern amenities, and create lasting memories." />
                <meta property="og:image" content="https://dahigaonlakeresortkarjat.in/static/media/Main%20Logo.57a41dca45fe9f6df438.jpeg" />
            </MetaTags>

            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className='AccommodationSection1'>
                <div className='Accommodation-banner'>
                    <NavBarComponent />
                    <div className='Accommodation-banner-text'>
                        {/* <h1 className='d-none'>
                            Resort in Karjat
                        </h1> */}
                        <div className='AS1-Text'>
                            <Zoom top>
                                <h1>Resort in Karjat</h1>
                            </Zoom>
                            <Fade bottom>
                                <p>Explore our collection of lavish accommodations and start planning your stay with us.</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>


            <div className='Room-titile'>
                <LightSpeed left cascade>
                    <h3>
                        Rooms
                    </h3>
                </LightSpeed>
                <Zoom>
                    <h2>
                        Rooms
                    </h2>
                </Zoom>
            </div>

            <Rooms />


            {/* //////////////////////////////////////  section 3 //////////////////////////////////// */}

            {/* <section className='AccommSection3'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='Accomo-right-con-1'>
                            <div className='Accomo-left-rooms-Image1'>
                                <img src={CoupleRoom002} alt="ac dormitory resort" />
                                <Slide left>
                                    <div className='Accomo-left-Couple-room-facility'>
                                        <h4>Facility</h4>
                                        <span><FaWifi /><FaBed /><MdAir /><FaToilet /><FaConciergeBell /><GiComputerFan /><MdOutlineMonitor /></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        <div className='Accomo-right-con-2'>
                            <div className='Accomo-left-rooms-Image2'>
                                <img src={CoupleRoom001} alt="ac dormitory rooms resort in karjat" />
                                <Slide left>
                                    <div className='Accomo-left-Couple-room-facility2'>
                                        <p>To enhance your perfect getaway, book one of our couple rooms with many facilities that make your trip memorable. We have 12 couple rooms for 2 people in our resort. </p>
                                        <span></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='Accomo-rooms-1'>
                            <div className='Accomo-rooms-Image1'>
                                <img src={CoupleRoom} alt="resort in karjat with ac dormitory" />
                                <Slide top>
                                    <div className='Accomo-rooms-overlay-con'>
                                        <h4>AC Dormitory Rooms</h4>
                                        <hr></hr>
                                        <div className='Room_price'>
                                            <h6>From</h6>
                                            <p>$ 1160.00 /night</p>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <hr></hr> */}

            {/* //////////////////////////////////////  section 4 //////////////////////////////////// */}


            {/* <section className='AccommSection4'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='Accomo-rooms-1'>
                            <div className='Accomo-rooms-Image1'>
                                <img src={FamilyRoom} alt="ac container rooms resort in karjat" />
                                <Slide bottom>
                                    <div className='Accomo-Family-rooms-overlay-con'>
                                        <h4>AC Container</h4>
                                        <hr></hr>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='Accomo-right-con-1'>
                            <div className='Accomo-left-rooms-Image1'>
                                <img src={FamilyRoom001} alt="resort in karjat with ac container rooms" />
                                <Slide right>
                                    <div className='Accomo-left-Family-room-facility'>
                                        <h4>Facility</h4>
                                        <span><FaWifi /><FaBed /><MdAir /><FaToilet /><FaConciergeBell /><GiComputerFan /><MdOutlineMonitor /></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        <div className='Accomo-right-con-2'>
                            <div className='Accomo-left-rooms-Image2'>
                                <img src={FamilyRoom002} alt="ac container family room resort in karjat" />
                                <Slide left>
                                    <div className='Accomo-left-Family-room-facility2'>
                                        <p>Sit back, relax, and renew your soul. Our family-friendly designed rooms are playful with one-of-a-kind vintage pieces, customized furniture and many more facilities. We have 4 family rooms for 4 people.</p>
                                        <span></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            {/* //////////////////////////////////////  section 3 //////////////////////////////////// */}

            {/* <section className='AccommSection3'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='Accomo-right-con-1'>
                            <div className='Accomo-left-rooms-Image1'>
                                <img src={CoupleRoom002} alt="couple room resort in karjat" />
                                <Slide left>
                                    <div className='Accomo-left-Couple-room-facility'>
                                        <h4>Facility</h4>
                                        <span><FaWifi /><FaBed /><MdAir /><FaToilet /><FaConciergeBell /><GiComputerFan /><MdOutlineMonitor /></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        <div className='Accomo-right-con-2'>
                            <div className='Accomo-left-rooms-Image2'>
                                <img src={CoupleRoom001} alt="resort for couple" />
                                <Slide left>
                                    <div className='Accomo-left-Couple-room-facility2'>
                                        <p>To enhance your perfect getaway, book one of our couple rooms with many facilities that make your trip memorable. We have 12 couple rooms for 2 people in our resort. </p>
                                        <span></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='Accomo-rooms-1'>
                            <div className='Accomo-rooms-Image1'>
                                <img src={CoupleRoom} alt="resort in karjat for couple" />
                                <Slide top>
                                    <div className='Accomo-rooms-overlay-con'>
                                        <h4>AC Dormitory for Groups</h4>
                                        <hr></hr>
                                        <div className='Room_price'>
                                            <h6>From</h6>
                                            <p>$ 1160.00 /night</p>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            {/* //////////////////////////////////////  section 4 //////////////////////////////////// */}


            {/* <section className='AccommSection4'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='Accomo-rooms-1'>
                            <div className='Accomo-rooms-Image1'>
                                <img src={FamilyRoom} alt="family room resort in karjat" />
                                <Slide bottom>
                                    <div className='Accomo-Family-rooms-overlay-con'>
                                        <h4>Family Cottage Room</h4>
                                        <hr></hr>
                                        <div className='Room_price'>
                                            <h6>From</h6>
                                            <p>$ 1160.00 /night</p>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='Accomo-right-con-1'>
                            <div className='Accomo-left-rooms-Image1'>
                                <img src={FamilyRoom001} alt="resort in karjat for Family" />
                                <Slide right>
                                    <div className='Accomo-left-Family-room-facility'>
                                        <h4>Facility</h4>
                                        <span><FaWifi /><FaBed /><MdAir /><FaToilet /><FaConciergeBell /><GiComputerFan /><MdOutlineMonitor /></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        <div className='Accomo-right-con-2'>
                            <div className='Accomo-left-rooms-Image2'>
                                <img src={FamilyRoom002} alt="family room with master bed resort in karjat" />
                                <Slide left>
                                    <div className='Accomo-left-Family-room-facility2'>
                                        <p>Sit back, relax, and renew your soul. Our family-friendly designed rooms are playful with one-of-a-kind vintage pieces, customized furniture and many more facilities. We have 4 family rooms for 4 people.</p>
                                        <span></span>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />


        </>
    )
}

export default AccommodationPage