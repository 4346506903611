import React, { useLayoutEffect } from 'react';
import "../CSS/Gallery.css";
import NavBarComponent from '../Components/NavBarComponent';
import { useState } from 'react';

import GalleryData from '../Components/GalleryData';
import Model from "../Components/Model.jsx";
import Footer from '../Components/Footer';


import Zoom from 'react-reveal/Zoom';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';
import { MetaTags } from 'react-meta-tags';

const Imagegallery = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [clickImage, setClickImage] = useState(null)
    const [cureentIndex, setCureentIndex] = useState(null)

    const handelClick = (item, index) => {

        setCureentIndex(index);
        setClickImage(item.Image);
        // console.log(clickImage)
        // // console.log(cureentIndex)
    };

    // const some = GalleryData.length
    // console.log(some)

    const handelRotationRight = () => {
        const totallength = GalleryData.length;

        if (cureentIndex + 1 >= totallength) {
            setCureentIndex(0);
            const newurl = GalleryData[0].Image;
            setClickImage(newurl)
            return;
        };
        const newIndex = cureentIndex + 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].Image;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totallength = GalleryData.length;
        if (cureentIndex === 0) {
            setCureentIndex(totallength - 1);
            const newUrl = GalleryData[totallength - 1].Image;
            setClickImage(newUrl);
        }
        const newIndex = cureentIndex - 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].Image;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    return (
        <>

            <MetaTags>
                <title>Lake View Resort in Karjat | Dahigaon Lake Resort</title>
                <meta title="Lake View Resort in Karjat | Dahigaon Lake Resort" />
                <meta name="description" content="Indulge in the beauty of nature at our Lake View Resort in Karjat. Enjoy breathtaking views, modern comforts, and a peaceful atmosphere. Book your stay!!!" />
                <meta name="keywords" content="Best Resort in Karjat,Best Lake Resort in Karjat,Resort in Karjat,Resort with Restaurant in Karjat,Lake View Resort in Karjat,Family Resort in Karjat,Top Lake Resort in Karjat" />
                <link rel="canonical" href="https://dahigaonlakeresortkarjat.in/lake-view-resort-karjat" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Lake View Resort in Karjat | Dahigaon Lake Resort" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dahigaonlakeresortkarjat.in/" />
                <meta property="og:description" content="Indulge in the beauty of nature at our Lake View Resort in Karjat. Enjoy breathtaking views, modern comforts, and a peaceful atmosphere. Book your stay!!!" />
                <meta property="og:image" content="https://dahigaonlakeresortkarjat.in/static/media/Main%20Logo.57a41dca45fe9f6df438.jpeg" />
            </MetaTags>


            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className='GallerySection1' >
                <div className='Gallery-banner'>
                    <NavBarComponent />
                    <div className='Gallery-banner-text'>
                        {/* <h1 className='d-none'>Lake View Resort in Karjat</h1> */}
                        <div className='GS1-Text'>
                            <Zoom top>
                                <h1>Lake View Resort in Karjat</h1>
                            </Zoom>
                            <Fade bottom>
                                <p>We dream in colors borrowed from the sea.</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section >




            {/* /////////////////////////// Section 2 /////////////////////////////////////////// */}

            <section className='Image-Gallery'>
                <div className='Image-Gallery-title'>
                    <LightSpeed left cascade>
                        <h4>Image gallery</h4>
                    </LightSpeed>
                    <Zoom>
                        <h3>Image gallery</h3>
                    </Zoom>
                </div>
                <div className='container'>
                    <div className='row'>
                        {
                            GalleryData.map((item, index) => {
                                return (
                                    <>
                                        <div className='col-md-4'>
                                            <Zoom>
                                                <div className='G-Images' key={index}>
                                                    <img src={item.Image} alt={item.Imagealt} onClick={() => handelClick(item, index)} />
                                                </div>
                                            </Zoom>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {
                clickImage && (
                    <Model
                        clickImage={clickImage}
                        handelRotationRight={handelRotationRight}
                        setClickImage={setClickImage}
                        handelRotationLeft={handelRotationLeft}
                    />
                )
            }
            <Footer />
        </>
    )
}

export default Imagegallery;