
import Image01 from "../Images/Gallery/Gallery 01.webp";
import Image02 from "../Images/Gallery/Gallery 02.webp";
import Image03 from "../Images/Gallery/Gallery 03.webp";
import Image04 from "../Images/Gallery/Gallery 04.webp";
import Image05 from "../Images/Gallery/Gallery 05.webp";
import Image06 from "../Images/Gallery/Gallery 06.webp";
import Image07 from "../Images/Gallery/Gallery 07.webp";
import Image08 from "../Images/Gallery/Gallery 08.webp";
import Image09 from "../Images/Gallery/Gallery 09.webp";
import Image10 from "../Images/Gallery/Gallery 10.webp";
import Image11 from "../Images/Gallery/Gallery 11.webp";
import Image12 from "../Images/Gallery/Gallery 12.webp";
import Image13 from "../Images/Gallery/Gallery 13.webp";
import Image14 from "../Images/Gallery/Gallery 14.webp";
import Image15 from "../Images/Gallery/Gallery 15.webp";
import Image16 from "../Images/Gallery/Gallery 16.webp";
import Image17 from "../Images/Gallery/Gallery 17.webp";
import Image18 from "../Images/Gallery/Gallery 18.webp";
import Image19 from "../Images/Gallery/Gallery 19.webp";
import Image20 from "../Images/Gallery/Gallery 20.webp";
import Image21 from "../Images/Gallery/Gallery 21.webp";
import Image22 from "../Images/Gallery/Gallery 22.webp";
import Image23 from "../Images/Gallery/Gallery 23.webp";
import Image24 from "../Images/Gallery/Gallery 24.webp";
import Image25 from "../Images/Gallery/Gallery 25.webp";
import Image26 from "../Images/Gallery/Gallery 26.webp";
import Image27 from "../Images/Gallery/Gallery 27.webp";
import Image28 from "../Images/Gallery/Gallery 28.webp";
import Image29 from "../Images/Gallery/Gallery 29.webp";
import Image30 from "../Images/Gallery/Gallery 30.webp";
import Image31 from "../Images/Gallery/Gallery 31.webp";
import Image32 from "../Images/Gallery/Gallery 32.webp";
import Image33 from "../Images/Gallery/Gallery 33.webp";
import Image34 from "../Images/Gallery/Gallery 34.webp";
import Image35 from "../Images/Gallery/Gallery 35.webp";
import Image36 from "../Images/Gallery/Gallery 36.webp";
import Image37 from "../Images/Gallery/Gallery 37.webp";
import Image38 from "../Images/Gallery/Gallery 38.webp";
import Image39 from "../Images/Gallery/Gallery 39.webp";
import Image40 from "../Images/Gallery/Gallery 40.webp";

import Offer from "../Images/Offer.jpeg";
import NewYear01 from "../Images/New Year 2023/New Year 01.webp";
import NewYear02 from "../Images/New Year 2023/New Year 02.webp";
import NewYear03 from "../Images/New Year 2023/New Year 03.webp";
import NewYear04 from "../Images/New Year 2023/New Year 04.webp";
import NewYear05 from "../Images/New Year 2023/New Year 05.webp";
import NewYear06 from "../Images/New Year 2023/New Year 06.webp";
import NewYear07 from "../Images/New Year 2023/New Year 07.webp";
import NewYear08 from "../Images/New Year 2023/New Year 08.webp";

import WeedingEvents01 from "../Images/Wedding Events/Wedding Events 01.jpeg";
import WeedingEvents02 from "../Images/Wedding Events/Wedding Events 02.jpeg";
import WeedingEvents03 from "../Images/Wedding Events/Wedding Events 03.jpeg";
import WeedingEvents04 from "../Images/Wedding Events/Wedding Events 04.jpeg";

import PoolImage01 from "../Images/Resort/IMG_3163.JPG";
import PoolImage02 from "../Images/Resort/IMG_3166.JPG";
import PoolImage03 from "../Images/Resort/IMG_3169.JPG";
import PoolImage04 from "../Images/Resort/IMG_3170.JPG";
import PoolImage05 from "../Images/Resort/IMG_3171.JPG";
import PoolImage06 from "../Images/Resort/IMG_3173.JPG";
import PoolImage07 from "../Images/Resort/IMG_3175.JPG";
import PoolImage08 from "../Images/Resort/IMG_3176.JPG";
import PoolImage09 from "../Images/Resort/IMG_3182.JPG";
import PoolImage10 from "../Images/Resort/IMG_3183.JPG";

import PlayingArea01 from "../Images/Resort/GOPR0710.JPG";
import PlayingArea02 from "../Images/Resort/IMG_3236.JPG";
import PlayingArea03 from "../Images/Resort/IMG_3239.JPG";
import PlayingArea04 from "../Images/Resort/IMG_3251.JPG";
import PlayingArea05 from "../Images/Resort/IMG_3262.JPG";
import PlayingArea06 from "../Images/Resort/IMG_3264.JPG";
import PlayingArea07 from "../Images/Resort/IMG_3265.JPG";

import Other01 from "../Images/Resort/GOPR0680.JPG";
import Other02 from "../Images/Resort/GOPR0691.JPG";
import Other03 from "../Images/Resort/IMG_3160.JPG";
import Other04 from "../Images/Resort/IMG_3221.JPG";
import Other05 from "../Images/Resort/IMG_3238.JPG";
import Other06 from "../Images/Resort/IMG_3247.JPG";
import Other07 from "../Images/Resort/IMG_3248.JPG";
import Other08 from "../Images/Resort/IMG_3249.JPG";
import Other09 from "../Images/Resort/IMG_3317.JPG";
import Other10 from "../Images/Resort/IMG_3327.JPG";
import Other11 from "../Images/Resort/IMG_3329.JPG";
import Other12 from "../Images/Resort/IMG_3330.JPG";
import Other13 from "../Images/Resort/IMG_3334.JPG";
import Other14 from "../Images/Resort/IMG_3335.JPG";
import Other15 from "../Images/Resort/IMG_3338.JPG";
import Other16 from "../Images/Resort/IMG_3347.JPG";
import Other17 from "../Images/Resort/IMG_3357.JPG";
import Other18 from "../Images/Resort/IMG_3362.JPG";
import Other19 from "../Images/Resort/IMG_3365.JPG";
import Other20 from "../Images/Resort/IMG_3377.JPG";
import Other21 from "../Images/Resort/IMG_3379.JPG";

const GalleryData = [
    {
        id: 1,
        Image: PoolImage01,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PoolImage02,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PoolImage03,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PoolImage04,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PoolImage05,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PoolImage06,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PoolImage07,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PoolImage08,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PoolImage09,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PoolImage10,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PlayingArea01,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PlayingArea02,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PlayingArea03,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PlayingArea04,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PlayingArea05,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PlayingArea06,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: PlayingArea07,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other01,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other02,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other03,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other04,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other05,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other06,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other07,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other08,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other09,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other10,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other11,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other12,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other13,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other14,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other15,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other16,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other17,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other18,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other19,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other20,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Other21,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Offer,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 1,
        Image: Image01,
        Imagealt: "view resort in karjat",
    },
    {
        id: 2,
        Image: Image02,
        Imagealt: "lake resort in karjat",
    },
    {
        id: 3,
        Image: Image03,
        Imagealt: "lake view resort in karjat",
    },
    {
        id: 4,
        Image: Image04,
        Imagealt: "lake view in karjat",
    },
    {
        id: 5,
        Image: Image05,
        Imagealt: "resort in karjat",
    },
    {
        id: 6,
        Image: Image06,
        Imagealt: "lake in karjat",
    },
    {
        id: 7,
        Image: Image07,
        Imagealt: "lake view resort karjat",
    },
    {
        id: 8,
        Image: Image08,
        Imagealt: "affordable price view lake resort",
    },
    {
        id: 9,
        Image: Image09,
        Imagealt: "affordable lake resort",
    },
    {
        id: 10,
        Image: Image10,
        Imagealt: "view lake in karjat",
    },
    {
        id: 11,
        Image: Image11,
        Imagealt: "Best Resort in Karjat",
    },
    {
        id: 12,
        Image: Image12,
        Imagealt: "Best Lake Resort in Karjat",
    },
    {
        id: 13,
        Image: Image13,
        Imagealt: "Resort with Restaurant in Karjat",
    },
    {
        id: 14,
        Image: Image14,
        Imagealt: "Family Resort in Karjat",
    },
    {
        id: 15,
        Image: Image15,
        Imagealt: "Top Lake Resort in Karjat",
    },
    {
        id: 16,
        Image: Image16,
        Imagealt: "Top Lake View Resort in Karjat",
    },
    {
        id: 17,
        Image: Image17,
        Imagealt: "Family Resort in Karjat",
    },
    {
        id: 18,
        Image: Image18,
        Imagealt: "Couple Resort in Karjat",
    },
    {
        id: 19,
        Image: Image19,
        Imagealt: "Restaurant in Karjat",
    },
    {
        id: 20,
        Image: Image20,
        Imagealt: "lake view Restaurant in Karjat",
    },
    {
        id: 21,
        Image: Image21,
        Imagealt: "best Restaurant in Karjat",
    },
    {
        id: 22,
        Image: Image22,
        Imagealt: "affordable Lake Resort in Karjat",
    },
    {
        id: 23,
        Image: Image23,
        Imagealt: "affordable Family resort in karjat",
    },
    {
        id: 24,
        Image: Image24,
        Imagealt: "best family resort in karjat",
    },
    {
        id: 25,
        Image: Image25,
        Imagealt: "event in resort",
    },
    {
        id: 26,
        Image: Image26,
        Imagealt: "best event in karajat resort",
    },
    {
        id: 27,
        Image: Image27,
        Imagealt: "Resort with Restaurant in Karjat",
    },
    {
        id: 28,
        Image: Image28,
        Imagealt: "karjat resort",
    },
    {
        id: 29,
        Image: Image29,
        Imagealt: "lake resort",
    },
    {
        id: 30,
        Image: Image30,
        Imagealt: "family resort",
    },
    {
        id: 31,
        Image: Image31,
        Imagealt: "couple resort",
    },
    {
        id: 32,
        Image: Image32,
        Imagealt: "best lake view resort in karjat",
    },
    {
        id: 33,
        Image: Image33,
        Imagealt: "best resort for birthday party",
    },
    {
        id: 34,
        Image: Image34,
        Imagealt: "party resort in karjat",
    },
    {
        id: 35,
        Image: Image35,
        Imagealt: "resort for Office Party",
    },
    {
        id: 36,
        Image: Image36,
        Imagealt: "resort for Office Party in karjat",
    },
    {
        id: 37,
        Image: Image37,
        Imagealt: "resort for wedding event",
    },
    {
        id: 38,
        Image: Image38,
        Imagealt: "best resort for wedding event in karjat",
    },
    {
        id: 39,
        Image: Image39,
        Imagealt: "Restaurant Resort in Karjat",
    },
    {
        id: 40,
        Image: Image40,
        Imagealt: "nonveg resort restaurant in karjat",
    },
    {
        id: 40,
        Image: NewYear01,
        Imagealt: "veg resort restaurant in karjat",
    },
    {
        id: 40,
        Image: NewYear02,
        Imagealt: "boating resort in karjat",
    },
    {
        id: 40,
        Image: NewYear03,
        Imagealt: "fishing near resort in karjat",
    },
    {
        id: 40,
        Image: NewYear04,
        Imagealt: "zipline in karjat",
    },
    {
        id: 40,
        Image: NewYear05,
        Imagealt: "tent house in karjat resort",
    },
    {
        id: 40,
        Image: NewYear06,
        Imagealt: "best activity in karjat resort",
    },
    {
        id: 40,
        Image: NewYear07,
        Imagealt: "cottages in karjat",
    },
    {
        id: 40,
        Image: NewYear08,
        Imagealt: "cottages resort in karjat",
    },
    {
        id: 40,
        Image: WeedingEvents01,
        Imagealt: "Weeding event in karjat",
    },
    {
        id: 40,
        Image: WeedingEvents02,
        Imagealt: "Weeding event resort in karjat",
    },
    {
        id: 40,
        Image: WeedingEvents03,
        Imagealt: "Resort for wedding in karjat",
    },
    {
        id: 40,
        Image: WeedingEvents04,
        Imagealt: "best event resort in karjat",
    },
]

export default GalleryData